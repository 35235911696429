import React from 'react'
import { FaBug } from "react-icons/fa";
import { Image } from "antd";
// import logo from '@/public/scalaLogo.png'


const Logo = ({onClick}: {onClick?: () => void}) => {
    return (
        <div className='text-xl cursor-pointer' onClick={onClick}>
            <img
                src='/scalaLogoDark.png'
                className='h-6'
            />
            {/* <FaBug /> */}
        </div>
    )
}

export default Logo